import axios from '@axios'
import { getUserData } from '@/auth/utils'

/**
 * 관리자 로그 API > 개인정보 열람 기록
 * @param {*} custId 조회 대상 고객 이름 또는, 고객 계정 또는, 고객 번호
 * @param {*} accessType // 조회 타입 (C - 생성, R - 읽기, U - 수정, D – 삭제, P – 개인정보다운로드)
 * @param {*} guardType // 조회한 정보 모두 나열 (ex: 이름|생년월일|이메일|휴대전화번호),
 * @param {*} etc // 비고
 */
export const custInfoInsert = async (custId, accessType, guardType, etc) => {
  try {
    const userData = getUserData()
    const currentUrl = window.location.href

    const defaultEtc = process.env.NODE_ENV === 'production' ? '고객 상세정보 조회' : '테스트'

    await axios.post('/fa/common/logging/cust-info/insert', {
      custId,
      userName: userData.userName, // 조회자 이름
      fullURLPath: currentUrl, // 조회 페이지 URL
      accessType,
      guardType,
      etc: etc || defaultEtc,
    })

    return true
  } catch (err) {
    return false
  }
}

export const test = () => {}
