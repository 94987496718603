<!-- 고객 상세정보 -->
<template>
  <div class="mt-2">
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="이름"
          label-for="member-full-name"
        >
          <b-form-input
            id="member-full-name"
            v-model="memberData.fullName"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="면허번호"
          label-for="member-hcp-license-number"
        >
          <b-form-input
            id="member-hcp-license-number"
            v-model="memberData.hcpLicenseNumber"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="휴대전화번호"
          label-for="member-phone-number"
        >
          <b-form-input
            id="member-phone-number"
            v-model="memberData.phoneNumber"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="성별"
          label-for="member-gender"
        >
          <b-form-input
            id="member-gender"
            v-model="memberData.gender"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="생년월일"
          label-for="member-birth-date"
        >
          <b-form-input
            id="member-birth-date"
            v-model="memberData.birthDate"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="근무처명"
          label-for="member-hcp-work-place"
        >
          <b-form-input
            id="member-hcp-work-place"
            v-model="memberData.hcpWorkPlace"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="진료과목"
          label-for="member-hcp-medical-dept"
        >
          <b-form-input
            id="member-hcp-medical-dept"
            v-model="memberData.hcpMedicalDept"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="근무형태"
          label-for="member-hcp-work-type"
        >
          <b-form-input
            id="member-hcp-work-type"
            v-model="memberData.hcpWorkType"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="근무처주소"
          label-for="member-hcp-work-address"
        >
          <b-form-input
            id="member-hcp-work-address"
            v-model="memberData.hcpWorkAddress"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="전공과목"
          label-for="member-hcp-major"
        >
          <b-form-input
            id="member-hcp-major"
            v-model="memberData.hcpMajor"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          v-if="memberData.hcpSpecType === '전문의'"
          label="전문의 취득연도"
          label-for="member-hcp-spec-year"
        >
          <b-form-input
            id="member-hcp-spec-year"
            v-model="memberData.hcpSpecYear"
            readonly
          />
        </b-form-group>
        <b-form-group
          v-else
          label="면허 취득연도"
          label-for="member-hcp-license-year"
        >
          <b-form-input
            id="member-hcp-license-year"
            v-model="memberData.hcpLicenseYear"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="전문의 여부"
          label-for="member-hcp-spec-type"
        >
          <b-form-input
            id="member-hcp-spec-type"
            v-model="memberData.hcpSpecType"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-form-group
          label="가입일시"
          label-for="member-join-date"
        >
          <b-form-input
            id="member-join-date"
            v-model="memberData.joinDate"
            readonly
          />
        </b-form-group>
      </b-col>

    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="1"
      >
        <b-form-group
          label="재가입여부"
          label-for="member-join-date"
        >
          <b-form-checkbox
            :checked="memberData.reJoinDate === null ? false : true"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="memberData.reJoinDate"
        cols="12"
        md="11"
      >
        <b-form-group
          label="재가입일시"
          label-for="member-rejoin-date"
        >
          <b-form-input
            id="member-rejoin-date"
            :value="memberData.reJoinDate"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="1"
      >
        <b-form-group
          label="프리패스 연동 처수"
          label-for="member-freepass-count"
        >
          <b-form-input
            id="member-freepass-count"
            :value="`${memberData.freePassClient.length}처`"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="11"
      >
        <b-form-group
          label="프리패스 연동 제약사"
          label-for="member-freepass-client"
        >
          <b-form-input
            id="member-freepass-client"
            :value="memberData.freePassClient.join(', ')"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@axios'
import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { custInfoInsert } from '@/common/logging/manage-log'

export default {
  components: {
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    watch(() => props.userId, newVal => {
      if (newVal === 0) memberData.value = { ...memberDataInitState }
      else fetchMember(newVal)
    })

    const toast = useToast()

    const memberDataInitState = {
      userId: null,
      fullName: null,
      hcpLicenseNumber: null,
      phoneNumber: null,
      gender: null,
      birthDate: null,
      hcpWorkPlace: null,
      hcpMedicalDept: null,
      hcpMajor: null,
      hcpSpecYear: null,
      hcpSpecType: null,
      hcpLicenseYear: null,
      joinDate: null,
      reJoinDate: null,
      freePassClient: [],
    }

    const memberData = ref({ ...memberDataInitState })

    const fetchMember = id => {
      axios.get(`/fa/user/${id}`)
        .then(rs => {
          memberData.value = rs.data

          // 개인정보 열람 기록(R)
          if (rs.data) custInfoInsert(rs.data.userId, 'R', '이름|생년월일|휴대전화번호|의료인면허번호|성별|근무처명|근무처주소', '')
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '고객 상세정보 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      memberData,
    }
  },
}
</script>
